//NOT EDITABLE!!!
import Hero from "../../components/Hero"
import Footer from "../../components/Footer"

// EDITABLE
import background from "../../assets/img/background.png" //Background



function pageLayout( props ) {
    //EDITABLE
    const deskripsi = ""
    const title1 = ""
    const title2 = ""
    const slogan = ''
    const copyright = ""

    return (
        <div>
            <Hero 
                background={background}
                backgroundMobile={background}
                deskripsi={deskripsi}
                title1={title1}
                title2={title2}
                slogan={slogan}
                
            >
                { props.children }
            </Hero>
            <Footer
                copyright={copyright}
            />
        </div>
    );
}


export default pageLayout;
